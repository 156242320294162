import { useState } from "react";
import { FaBars } from "react-icons/fa";
import ListMenu from "./ListMenu";
import { useLocation } from 'react-router-dom';

function UpperMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  
  const onResumeClick = () => {
    let tempLink = document.createElement("a");
    tempLink.href = `${process.env.PUBLIC_URL}/pdf/Oliver_Linares_Colinas_CV.pdf`;
    tempLink.download = `${process.env.PUBLIC_URL}/pdf/Oliver_Linares_Colinas_CV.pdf`;
    tempLink.click();
  };

  return (
    <div className="row-span-1 col-span-5 flex flex-wrap items-center">
      <div className="w-full">
        <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-emerald-500 rounded">
          <div className="container mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative flex justify-end md:w-auto md:static md:block md:justify-start pr-2">
              {/* Resume button for <md screens */}
              <div className="md:hidden pr-10 block">
                <div
                  className="cursor-pointer border-2 border-cyan rounded-md py-2 px-4 text-center hover:opacity-80 select-none"
                  onClick={onResumeClick}
                >
                  <font className="text-cyan">Resume</font>
                </div>
              </div>
              <button
                className="text-white cursor-pointer text-xl block md:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <FaBars size={30} />
              </button>
            </div>
            <div
              className={
                "md:flex flex-grow items-center z-10" +
                (menuOpen ? " flex flex-row-reverse" : " hidden")
              }
              id="example-navbar-info"
            >
              <ul className="flex flex-col md:flex-row list-none md:ml-auto bg-dark rounded-lg md:bg-transparent">
                <ListMenu
                  number={"01."}
                  name={"About"}
                  url={"/"}
                  current={location.pathname === "/"}
                />
                <ListMenu
                  number={"02."}
                  name={"Experience"}
                  url={"experience"}
                  current={location.pathname === "/experience"}
                />
                <ListMenu
                  number={"03."}
                  name={"Work"}
                  url={"work"}
                  current={location.pathname === "/work"}
                />
                <ListMenu
                  number={"04."}
                  name={"Contact"}
                  url={"contact"}
                  current={location.pathname === "/contact"}
                />
              </ul>
            </div>
            {/* Resume button for >md screens */}
            <div className="hidden md:pl-10 md:block">
              <div
                className="cursor-pointer border-2 border-cyan rounded-md py-2 px-4 text-center hover:opacity-80"
                onClick={onResumeClick}
              >
                <font className="text-cyan">Resume</font>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default UpperMenu;
