import entries from "../Entries.json";
import WorkCard from "./WorkCard";
import { useEffect } from "react";
import { useRef } from "react";

function Work() {
  const ref = useRef();

  useEffect(() => {
    const scroll = window.localStorage.getItem("WorkScroll");
    if (scroll != null) {
      ref.current.scrollTo({
        top: scroll,
        behavior: "auto",
      });
    }
  }, []);

  const save = (e) => {
    window.localStorage.setItem("WorkScroll", e.target.scrollTop);
  };

  return (
    <div
      className="pl-10 md:pl-0 row-span-5 col-span-5 overflow-auto mb-5 scrollbar-thumb-green scrollbar-thin scrollbar-thumb-rounded-md"
      ref={ref}
      onScroll={save}
    >
      {entries.slice().reverse().map((entry) => {
        return (
          <WorkCard
            key={entry.id}
            id={entry.id}
            title={entry.title}
            subtitle={entry.subtitle}
            description={entry.description}
            imagen={entry.preview}
            imageList={entry.images}
          />
        );
      })}
    </div>
  );
}

export default Work;
