import { Link } from "react-router-dom";

function WorkCard({ title, subtitle, description, imagen, id, imageList }) {
  return (
    <Link
      className="cursor-pointer border-2 border-cyan rounded-xl p-4 bg-formTransparent bg-opacity-20 mr-5 lg:mr-40 grid grid-cols-4 grid-rows-6 mt-10 hover:opacity-80 active:opacity-60"
      to={`${id}`}
    >
      <div
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/entries/${id}/${imagen})`,
        }}
        className="aspect-w-16 aspect-h-9 col-span-6 md:col-span-1 lg:col-span-1 row-span-2 md:row-span-6 mx-10 my-0 md:mx-5 border-2 border-dark rounded-3xl flex justify-center items-center bg-no-repeat bg-cover bg-center"
      ></div>
      <div
        className="col-span-6 md:col-span-3 lg:col-span-3 text-xl flex items-center"
        id="fontLigBold"
      >
        {title}
      </div>
      <div
        className="col-span-6 md:col-span-3 lg:col-span-3 flex items-center"
        id="fontLigBold"
      >
        {subtitle}
      </div>
      <div className="col-span-6 md:col-span-3 lg:col-span-3 row-span-2 text-whiteGreen">
        {description}
      </div>
    </Link>
  );
}

export default WorkCard;
