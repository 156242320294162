import ContactForm from "./ContactForm";

function Contact() {
    return (
        <div className="pl-10 md:pl-0 row-span-5 col-span-5">
            <ContactForm />
        </div>
    );
}

export default Contact;