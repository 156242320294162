import { Link } from "react-router-dom";

function LeftPanel() {
  return (
    <div className="grid grid-col grid-rows-5 md:grid-rows-6 row-span-6 col-span-1 h-full ml-0 md:ml-10 w-24 ">
      <div
        style={{
          backgroundImage: `url("${process.env.PUBLIC_URL}/images/Pentagon.png")`,
        }}
        className="bg-no-repeat bg-contain bg-bottom text-6xl"
      ></div>
      <div className="row-span-1 md:row-span-3 grid grid-cols-1 place-items-center mb-4">
        <div className="h-full border-white border-2"></div>
      </div>
      <div className="row-span-2 md:row-span-1 grid grid-cols-1 grid-rows-3 gap-4">
        <Link
          style={{
            backgroundImage: `url("${process.env.PUBLIC_URL}/images/github-mark-white.svg")`,
          }}
          className="cursor-pointer bg-no-repeat bg-contain bg-center bg-origin-content p-3 md:p-1 hover:opacity-70 text-transparent select-none text-center text-sm"
          to={"https://github.com/oliverito11"}
          target="_blank"
          rel="noopener noreferrer"
        />
        <Link
          style={{
            backgroundImage: `url("${process.env.PUBLIC_URL}/images/Linkedin.png")`,
          }}
          className="cursor-pointer bg-no-repeat bg-contain bg-center bg-origin-content p-0 hover:opacity-70 text-transparent select-none text-center text-sm"
          to={"https://www.linkedin.com/in/oliver-linares-colinas-a50353222/"}
          target="_blank"
          rel="noopener noreferrer"
        />
        <Link
          style={{
            backgroundImage: `url("${process.env.PUBLIC_URL}/images/gmail.png")`,
          }}
          className="cursor-pointer bg-no-repeat bg-contain bg-center bg-origin-content p-3 md:p-1 hover:opacity-70 text-transparent select-none text-center text-sm"
          to={"mailto:oliver.linarescolinas@gmail.com"}
          target="_blank"
          rel="noopener noreferrer"
        />
      </div>
      <div className="row-span-2 grid grid-cols-1 place-items-center mt-4">
        <div className="h-full border-white border-2"></div>
      </div>
    </div>
  );
}

export default LeftPanel;
