function Experience() {
  return (
    <div className="pl-10 md:pl-0 row-span-5 col-span-5 pt-32 md:pt-48 overflow-auto scrollbar-thumb-green scrollbar-thin scrollbar-thumb-rounded-md">
      <div className="w-11/12 md:w-9/12">
        <div id="fontLigBold" className="text-xl border-b-2 border-whiteGreen2 mb-3">Software developer</div>
        <div id="fontLigBold" className="text-5xl text-whiteGreen">SERBATIC</div>
        <div id="fontLigBold" className="text-2xl text-green3 mb-3">Spain, Castille and Leon, Zamora</div>
        <div id="fontLigBold" className="text-whiteGreen2">
          <font className="text-whiteGreen">Java</font> experience with <font className="text-whiteGreen">Hibernate</font>, <font className="text-whiteGreen">Spring</font> and <font className="text-whiteGreen">API REST/SOAP. <br/>Middleware </font>
          experience with <font className="text-whiteGreen">OSB</font> and <font className="text-whiteGreen">BPEL<br/>Agile development</font> 
        </div>
      </div>
    </div>
  );
}

export default Experience;
