import { Link, useParams } from "react-router-dom";
import entries from "../Entries.json";

function Entry() {
  const { id } = useParams();
  let entry = entries.at(id);

  const renderData = () => {
    let result = [];
    let count = 0;
    let imageCount = 0;
    entry.content.forEach((c) => {
      if (c.startsWith("[") && c.endsWith("]")) {
        result.push(
          <img
            key={"image" + count++}
            src={`${process.env.PUBLIC_URL}/images/entries/${id}/${
              entry.images[imageCount++]
            }`}
            alt={"image" + count++}
            className="max-w-full lg:max-w-3xl my-5 rounded-3xl border-2 border-cyan"
          />
        );
      } else if (c.startsWith("link:")) {
        result.push(
          <a
            className="text-cyan hover:underline"
            key={"a" + count++}
            href={c.substring(c.indexOf(":") + 1, c.indexOf("*"))}
            target="_blank"
            rel="noreferrer noopener"
          >
            &gt;&gt; {c.substring(c.indexOf(":") + 1, c.indexOf("*"))} &lt;&lt;
          </a>
        );
      } else if (c.startsWith("youtube:")) {
        result.push(
          <div className="my-5 aspect-w-16 aspect-h-9 md:w-full md:max-w-4xl lg:aspect-h-5" key={"iframe" + count++}>
            <iframe
              className="video rounded-3xl border-2 border-cyan h-full w-full"
              src={c.substring(c.indexOf("*") + 1)}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        );
      } else {
        result.push(<p key={"text" + count++}>{c}</p>);
      }
    });
    return result;
  };
  return (
    <div className="pl-10 xs:pl-10 md:-ml-10 lg:-ml-20 row-span-5 col-span-5 overflow-auto scrollbar-thumb-green scrollbar-thin scrollbar-thumb-rounded-md">
      <div className="sticky -top-1 bg-green2 p-2">
        <div className="text-cyan hover:underline cursor-pointer w-fit">
          <Link to="/work">&lt;&lt; Go back</Link>
        </div>
      </div>
      <div id="fontLigBold" className="text-4xl text-whiteGreen">
        {entry.title}
      </div>
      <div id="fontLigBold" className="text-2xl text-green3 mb-5">
        {entry.subtitle}
      </div>
      <div>
        {renderData()}
        {entry.download && entry.download !== "" ? (
          <a
            href={entry.download}
            className="text-cyan pb-6 hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Try it!
          </a>
        ) : (
          false
        )}
      </div>
    </div>
  );
}

export default Entry;
