import "./index.css";
import LeftPanel from "./Components/LeftPanel";
import UpperMenu from "./Components/UpperMenu";
import About from "./Components/About";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Experience from "./Components/Experience";
import Work from "./Components/Work";
import Contact from "./Components/Contact";
import Entry from "./Components/Entry";

function App() {
  return (
    <div className="h-screen w-screen grid grid-rows-6 grid-cols-6">
      <BrowserRouter>
        {/* Left panel */}
        <LeftPanel />
        {/* Upper menu */}
        <UpperMenu />
        {/* Content */}
        <Routes>
          <Route path="/">
            <Route index element={<About />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/work" element={<Work />} />
            <Route path="/work/:id" element={<Entry />} />
            <Route path="/contact" element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
