import { Link } from "react-router-dom";

function About() {
  return (
    <div className="pl-10 md:pl-0 row-span-5 col-span-5 overflow-auto scrollbar-thumb-green scrollbar-thin scrollbar-thumb-rounded-md">
      <div className="text-white text-xl md:text-base">Hi, my name is</div>
      <div>
        <font
          className="text-whiteGreen text-5xl md:text-6xl lg:text-8xl"
          id="fontLigBold"
        >
          Oliver Linares Colinas.
        </font>
        <br />
        <font
          className="text-green3 text-4xl md:text-6xl lg:text-8xl"
          id="fontLigBold"
        >
          I build games.
        </font>
      </div>
      <div className="pt-20 w-10/12 lg:w-2/4 xl:w-1/4">
        <font className="text-green text-xl md:text-lg" id="fontLigBold">
          I’m a <font className="text-whiteGreen2">software developer</font> who
          likes to develop videogames using{" "}
          <font className="text-whiteGreen2">Unreal Engine 5</font> with{" "}
          <font className="text-whiteGreen2">C++</font>. I can create
          interesting mechanics.
        </font>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-8 grid-rows-1 pt-20">
        <Link
          className="cursor-pointer col-span-1 md:col-span-1 lg:col-span-2 xl:col-span-1 border-2 border-cyan rounded-md p-4 text-center hover:opacity-80"
          to={"work"}
        >
          <font className="text-cyan select-none">Learn more</font>
        </Link>
      </div>
    </div>
  );
}

export default About;
