function ContactForm() {
  return (
    <div className="w-full md:w-4/5 h-full rounded-lg md:p-10 pr-5 overflow-auto scrollbar-thumb-green scrollbar-thin scrollbar-thumb-rounded-md">
      <div className="mb-5">Get in contact with me via:<br /><br/>
      E-mail: <br/>
      <a className="text-cyan hover:underline" href="mailto:oliver.linarescolinas@gmail.com">oliver.linarescolinas@gmail.com</a><br/><br/>
      LinkedIn: <br/>
      <a className="text-cyan hover:underline"
          href="https://www.linkedin.com/in/oliver-linares-colinas-a50353222/"
          target="_blank"
          rel="noreferrer">https://www.linkedin.com/in/oliver-linares-colinas-a50353222/</a><br/><br/>
      Phone: <br/>
      <p className="text-cyan">+34 656 346 405</p></div><br/>
    </div>
  );
}

export default ContactForm;
